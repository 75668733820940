var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "align-start"
  }, [_c('span', {
    staticClass: "font-weight-semibold text-base"
  }, [_vm._v("Statistics")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('v-avatar', {
    staticClass: "elevation-1",
    attrs: {
      "size": "44",
      "color": "info",
      "rounded": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": "",
      "color": "white",
      "size": "30"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiCurrencyUsd) + " ")])], 1), _c('div', {
    staticClass: "ms-3"
  }, [_c('p', {
    staticClass: "text-xs mb-0"
  }, [_vm._v("payment due (HealthCoin Balance) ")]), _c('span', {
    staticClass: "text--primary text-xl font-weight-bold"
  }, [_vm._v(_vm._s(_vm.healthCoinCount))])])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }