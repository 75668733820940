<template>
  <v-form ref="formElem">
    <translation-locales />
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.type"
          label="Type"
          item-text="title"
          item-value="value"
          outlined
          :items="typeOptions"
        ></v-select>
        <translatable-input
          v-model="form.name"
          label="Name"
          outlined
          :rules="[required]"
          class="mt-5"
        />
        <!-- <v-text-field v-model="form.name" :rules="[required]" label="Name" outlined /> -->
        <v-text-field
          v-model="form.description"
          :rules="[required]"
          label="Description"
          outlined
        />
        <v-text-field
          v-model="form.max_rewards"
          label="Max Rewards"
          outlined
        />
        <v-text-field
          v-model="form.reward_points"
          label="Reward Points"
          outlined
        />
        <v-text-field
          v-model="form.expiry_days"
          label="Expiry Days"
          outlined
        />
        <v-select
          v-model="form.status"
          label="Status"
          item-text="title"
          item-value="value"
          outlined
          :items="statusOptions"
        ></v-select>
        <h3 class="mt-2">
          Mutally Exclusive
        </h3>
        <v-switch
          v-model="form.is_mutually_exclusive"
          color="primary"
          true-value="true"
          false-value="false"
          :label="'Mutally Exclusive'"
        ></v-switch>
        <h3 class="mt-2">
          From <span style="color: red">*</span>
        </h3>
        <v-date-picker
          v-model="form.started_at"
          :rules="[required]"
        />
        <v-divider class="mt-2"></v-divider>
        <h3 class="mt-2">
          To <span style="color: red">*</span>
        </h3>
        <v-date-picker
          v-model="form.ended_at"
          :rules="[required]"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { now } from '@/@core/utils/filter'
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })
    const typeOptions = [
      {
        title: 'Registration',
        value: 'registration',
      },
    ]
    const statusOptions = [
      {
        title: 'Published',
        value: 'published',
      },
      {
        title: 'Unpublished',
        value: 'unpublished',
      },
      {
        title: 'Draft',
        value: 'draft',
      },
      {
        title: 'Scheduled',
        value: 'scheduled',
      },
      {
        title: 'Expired',
        value: 'expired',
      },
    ]

    const initialForm = {
      ...field('type', 'registration'),
      ...field('name', useTranslatable(null)),
      ...field('description', ''),
      ...field('max_rewards', 0),
      ...field('reward_points', 0),
      ...field('is_mutually_exclusive', false),
      ...field('status', 'draft'),
      ...field('started_at', null),
      ...field('ended_at', null),
      ...field('expiry_days', 365),
    }

    if (initialForm.started_at) {
      initialForm.started_at = now(initialForm.started_at).format('YYYY-MM-DD')
    }
    if (initialForm.ended_at) {
      initialForm.ended_at = now(initialForm.ended_at).format('YYYY-MM-DD')
    }

    const form = ref({
      ...initialForm,
    })

    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      if (!data.started_at) return
      if (!data.ended_at) return

      data.started_at = `${now(data.started_at).format('YYYY-MM-DD').toString()}T00:00:00+08:00`
      data.ended_at = `${now(data.ended_at).format('YYYY-MM-DD').toString()}T23:59:59+08:00`
      emit('submit', data)
    }

    return {
      form,
      formElem,
      validate,
      required,
      typeOptions,
      statusOptions,
    }
  },
}
</script>
