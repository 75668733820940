<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.category"
          label="Category"
          :items="categoryOptions"
          outlined
        />
        <v-select
          v-model="form.key"
          :label="'Key'"
          :items="keyOptions.find((obj) => obj.hasOwnProperty(form.category))?.[form.category] ?? []"
          outlined
        />
        <v-select
          v-model="form.value_type"
          disabled
          :label="'Value Type'"
          :items="['array','boolean','integer','string']"
          outlined
        />
        <v-text-field
          v-model="form.value"
          label="Value"
          outlined
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }} The key may already exist. Please use the edit function to modify the value.
      </v-alert>

      <p>Hints: The available key options will be displayed after a category is selected.</p>
    </v-card>
  </v-form>
</template>

<script>
import router from '@/router'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

import { useNotifyErrors } from '@/composables'
import { fetchDistributorSettingsKeyMap } from '@api/distributor/distributor'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('category', ''),
      ...field('key', ''),
      ...field('value', ''),
      ...field('value_type', ''),
    }

    const form = ref({
      ...initialForm,
    })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const distributorSettingsKeyMap = ref(null)
    const categoryOptions = ref(null)
    const keyOptions = ref(null)

    const loadDistributorSettingsKeyMap = id => fetchDistributorSettingsKeyMap(id)
      .then(({ data }) => {
        distributorSettingsKeyMap.value = data.data
        console.log(distributorSettingsKeyMap.value)

        categoryOptions.value = Object.keys(distributorSettingsKeyMap.value)
        keyOptions.value = categoryOptions.value.reduce((acc, category) => {
          acc.push({
            [category]: distributorSettingsKeyMap.value[category],
          })

          return acc
        }, [])

        console.log('categoryOptions:', categoryOptions.value)
        console.log('keyOptions:', keyOptions.value)
      })
      .catch(useNotifyErrors)

    onMounted(async () => {
      loadDistributorSettingsKeyMap(router.currentRoute.params.id)
    })

    const integerKeys = [
      'invoice_day',
      'financial_start_month',
      'credits_per_year',
      'financial_start_day',
      'accumulate_days',
      'active_user',
      'used_credit',
      'pnc_commission',
      'length',
      'reward_point_days_per_report',
    ]
    const arrayKeys = ['categories', 'brands']
    const boolKeys = ['can_accumulate', 'auto_distribute']
    const stringKeys = ['next_benefit_distribution_date', 'generate_strategy', 'prefix']

    watch(
      () => form.value.key,
      newKey => {
        if (integerKeys.includes(newKey)) {
          form.value.value_type = 'integer'
        }
        if (arrayKeys.includes(newKey)) {
          form.value.value_type = 'array'
        }
        if (boolKeys.includes(newKey)) {
          form.value.value_type = 'boolean'
        }
        if (stringKeys.includes(newKey)) {
          form.value.value_type = 'string'
        }
      },
    )

    return {
      form,
      formElem,
      distributorSettingsKeyMap,
      loadDistributorSettingsKeyMap,
      categoryOptions,
      keyOptions,
      validate,
      required,
    }
  },
}
</script>
