var render = function () {
  var _vm$keyOptions$find$_, _vm$keyOptions$find;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "Category",
      "items": _vm.categoryOptions,
      "outlined": ""
    },
    model: {
      value: _vm.form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }), _c('v-select', {
    attrs: {
      "label": 'Key',
      "items": (_vm$keyOptions$find$_ = (_vm$keyOptions$find = _vm.keyOptions.find(function (obj) {
        return obj.hasOwnProperty(_vm.form.category);
      })) === null || _vm$keyOptions$find === void 0 ? void 0 : _vm$keyOptions$find[_vm.form.category]) !== null && _vm$keyOptions$find$_ !== void 0 ? _vm$keyOptions$find$_ : [],
      "outlined": ""
    },
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  }), _c('v-select', {
    attrs: {
      "disabled": "",
      "label": 'Value Type',
      "items": ['array', 'boolean', 'integer', 'string'],
      "outlined": ""
    },
    model: {
      value: _vm.form.value_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value_type", $$v);
      },
      expression: "form.value_type"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Value",
      "outlined": ""
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " The key may already exist. Please use the edit function to modify the value. ")]);
  }), _c('p', [_vm._v("Hints: The available key options will be displayed after a category is selected.")])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }