var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mx-5"
  }, [_c('v-row', {
    staticClass: "mt-5"
  }, [_vm._l(_vm.form, function (item, itemIndex) {
    return _c('v-col', {
      key: itemIndex,
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('label', [_vm._v(_vm._s(_vm.convertString(itemIndex)))])]), _c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [itemIndex == 'brands' ? _c('v-select', {
      attrs: {
        "item-text": function itemText(item) {
          return _vm.t(item.data.name);
        },
        "item-value": "data.id",
        "outlined": "",
        "multiple": "",
        "items": _vm.brandOptions
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : itemIndex == 'categories' ? _c('v-select', {
      attrs: {
        "item-text": function itemText(item) {
          return _vm.t(item.data.name);
        },
        "item-value": "data.id",
        "outlined": "",
        "multiple": "",
        "items": _vm.catOptions
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : itemIndex == 'channel_note_keys' ? _c('v-select', {
      attrs: {
        "item-text": function itemText(item) {
          return item.key;
        },
        "item-value": function itemValue(item) {
          return item.key;
        },
        "outlined": "",
        "multiple": "",
        "items": _vm.noteOptions
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : itemIndex == 'generate_strategy' ? _c('v-select', {
      attrs: {
        "item-text": "name",
        "item-value": "value",
        "outlined": "",
        "items": _vm.generateStrategyOptions
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : itemIndex == 'can_accumulate' || itemIndex == 'auto_distribute' ? _c('v-select', {
      attrs: {
        "item-text": "name",
        "item-value": "value",
        "outlined": "",
        "items": _vm.boolOptions
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : itemIndex == 'core:order_report_email' ? _c('v-text-field', {
      attrs: {
        "item-text": "name",
        "item-value": "value",
        "multiple": "",
        "label": "separate by comma (a@b.com,c@d.com)"
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : itemIndex == 'core:report_password' ? _c('v-text-field', {
      attrs: {
        "item-text": "name",
        "item-value": "value",
        "multiple": "",
        "type": "password",
        "hint": "protected export sheets; empty passwords still show as hased."
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    }) : _c('v-text-field', {
      attrs: {
        "disabled": itemIndex == 'next_benefit_distribution_date',
        "outlined": ""
      },
      model: {
        value: _vm.form[itemIndex],
        callback: function callback($$v) {
          _vm.$set(_vm.form, itemIndex, $$v);
        },
        expression: "form[itemIndex]"
      }
    })], 1)], 1)], 1);
  }), _vm.type['core:order'] ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ul', [_c('b', [_vm._v("xxxx_days_per_report")]), _c('li', [_vm._v("7 = Send xxxx per week (7 Days)")]), _c('li', [_vm._v("1 = Send xxxx every day")]), _c('li', [_vm._v("0 = Don’t send xxxx at all.")]), _c('li', [_vm._v("if there is no order, it still sends.")])]), _c('ul', [_c('b', [_vm._v("xxxx_report_email")]), _c('li', [_vm._v("Bcc receipients. Should test on UAT first,")]), _c('li', [_vm._v("then set to internal email on PROD as final buffer")]), _c('li', [_vm._v("check internal email's content.")]), _c('li', [_vm._v("You can set it to external emails if content's correct.")])])]) : _vm._e(), _vm.type['core:order'] || _vm.type['reward_point'] ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ul', [_c('b', [_vm._v("Send to Blue SAS")]), _c('li', [_vm._v("xxxx_report_storage_driver: azure_sas")]), _c('li', [_vm._v("xxxx_report_storage_export_extension: json")]), _c('li', [_vm._v("xxxx_report_storage_target_url: https://[[name]].blob.core.windows.net/[[container]]/[[file_name]]?[[token]]")]), _c('li', [_vm._v("To fulfill trickier requirements, You may set target_url to static values instead of [[]]")]), _c('li', [_vm._v("but [[file_name]]?[[token]] are always required in the target url.")]), _c('li', [_vm._v("Leave them all empty = turn off.")])])]) : _vm._e()], 2), _c('v-row', [_c('v-col', {
    staticClass: "text-right"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Save ")])], 1)], 1), _c('v-row', [_c('VSnackbar', {
    attrs: {
      "top": "",
      "timeout": 3000,
      "color": "success"
    },
    model: {
      value: _vm.success,
      callback: function callback($$v) {
        _vm.success = $$v;
      },
      expression: "success"
    }
  }, [_vm._v(" Distributor Settings Updated. ")]), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }