var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "Type",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.typeOptions
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Name",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.required],
      "label": "Description",
      "outlined": ""
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Max Rewards",
      "outlined": ""
    },
    model: {
      value: _vm.form.max_rewards,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "max_rewards", $$v);
      },
      expression: "form.max_rewards"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Reward Points",
      "outlined": ""
    },
    model: {
      value: _vm.form.reward_points,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reward_points", $$v);
      },
      expression: "form.reward_points"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Expiry Days",
      "outlined": ""
    },
    model: {
      value: _vm.form.expiry_days,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expiry_days", $$v);
      },
      expression: "form.expiry_days"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Status",
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "items": _vm.statusOptions
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v(" Mutally Exclusive ")]), _c('v-switch', {
    attrs: {
      "color": "primary",
      "true-value": "true",
      "false-value": "false",
      "label": 'Mutally Exclusive'
    },
    model: {
      value: _vm.form.is_mutually_exclusive,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_mutually_exclusive", $$v);
      },
      expression: "form.is_mutually_exclusive"
    }
  }), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v(" From "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('v-date-picker', {
    attrs: {
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.started_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "started_at", $$v);
      },
      expression: "form.started_at"
    }
  }), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('h3', {
    staticClass: "mt-2"
  }, [_vm._v(" To "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('v-date-picker', {
    attrs: {
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.ended_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ended_at", $$v);
      },
      expression: "form.ended_at"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }