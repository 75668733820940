<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <distributor-profile-panel
          v-if="distributor"
          :distributor="distributor"
          @openEditPhotoAside="setAside"
          @updated="reload"
        ></distributor-profile-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-row>
          <v-col cols="12">
            <!-- :checkout-count="user.checkouts_count" -->
            <distributor-statistics
              v-if="distributor"
              :health-coin-count="Number(distributorBalance.total ?? 0)"
            ></distributor-statistics>
          </v-col>

          <v-col cols="12">
            <v-tabs
              v-model="distributorTab"
              show-arrows
              class="user-tabs"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.title"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              id="user-tabs-content"
              v-model="distributorTab"
              class="mt-5 pa-1"
            >
              <v-tab-item>
                <distributor-team-list
                  v-if="$can('distributor_team:list', 'distributor_team_management')"
                  :distributor-id="distributorId"
                />
              </v-tab-item>

              <v-tab-item>
                <v-btn
                  color="primary"
                  @click="openDialog"
                >
                  Edit Settings
                </v-btn>

                <v-dialog
                  v-model="dialog"
                  max-width="800px"
                >
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Distributor Settings</span>
                      <!-- {{keyOptions}} -->
                    </v-card-title>

                    <v-tabs
                      v-model="dialogTab"
                      background-color="transparent"
                    >
                      <v-tab>Blacklist</v-tab>
                      <v-tab>Whitelist</v-tab>
                      <v-tab>Notification</v-tab>
                      <v-tab>Reward Point</v-tab>
                      <v-tab>Core</v-tab>
                      <v-tab v-if="isMedicalCardSettingsEnabled">
                        Medical Card
                      </v-tab>
                      <v-tab v-if="isEmployeeBenefitEnabled">
                        Financial
                      </v-tab>
                      <v-tab v-if="isEmployeeBenefitEnabled">
                        Invoice
                      </v-tab>
                      <v-tab v-if="isEmployeeBenefitEnabled">
                        Pricing
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="dialogTab">
                      <v-tab-item>
                        <v-card-text>
                          <div
                            v-for="(category, index) in keyOptions"
                            :key="index"
                          >
                            <settings-category
                              v-if="category.blacklist"
                              :category-items="category.blacklist"
                              :settings="distributorSettings"
                              :type="category"
                              :distributor-id="distributorId"
                            >
                            </settings-category>
                          </div>
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card-text>
                          <div
                            v-for="(category, index) in keyOptions"
                            :key="index"
                          >
                            <settings-category
                              v-if="category.whitelist"
                              :category-items="category.whitelist"
                              :settings="distributorSettings"
                              :type="category"
                              :distributor-id="distributorId"
                            >
                            </settings-category>
                          </div>
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card-text>
                          ...
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item>
                        <div
                          v-for="(category, index) in keyOptions"
                          :key="index"
                        >
                          <settings-category
                            v-if="category['reward_point']"
                            :category-items="category['reward_point']"
                            :settings="distributorSettings"
                            :type="category"
                            :distributor-id="distributorId"
                          >
                          </settings-category>
                        </div>
                      </v-tab-item>
                      <v-tab-item>
                        <div
                          v-for="(category, index) in keyOptions"
                          :key="index"
                        >
                          <settings-category
                            v-if="category['core:order']"
                            :category-items="category['core:order']"
                            :settings="distributorSettings"
                            :type="category"
                            :distributor-id="distributorId"
                          >
                          </settings-category>
                        </div>
                      </v-tab-item>
                      <v-tab-item v-if="isMedicalCardSettingsEnabled">
                        <v-card-text>
                          <div
                            v-for="(category, index) in keyOptions"
                            :key="index"
                          >
                            <settings-category
                              v-if="category.medical_card"
                              :category-items="category.medical_card"
                              :settings="distributorSettings"
                              :type="category"
                              :distributor-id="distributorId"
                            >
                            </settings-category>
                          </div>
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item v-if="isEmployeeBenefitEnabled">
                        <div
                          v-for="(category, index) in keyOptions"
                          :key="index"
                        >
                          <settings-category
                            v-if="category.financial"
                            :category-items="category.financial"
                            :settings="distributorSettings"
                            :type="category"
                            :distributor-id="distributorId"
                            :distributor-features="distributor.features"
                          >
                          </settings-category>
                        </div>
                      </v-tab-item>
                      <v-tab-item v-if="isEmployeeBenefitEnabled">
                        <div
                          v-for="(category, index) in keyOptions"
                          :key="index"
                        >
                          <settings-category
                            v-if="category.invoice"
                            :category-items="category.invoice"
                            :settings="distributorSettings"
                            :type="category"
                            :distributor-id="distributorId"
                          >
                          </settings-category>
                        </div>
                      </v-tab-item>
                      <v-tab-item v-if="isEmployeeBenefitEnabled">
                        <div
                          v-for="(category, index) in keyOptions"
                          :key="index"
                        >
                          <settings-category
                            v-if="category.pricing"
                            :category-items="category.pricing"
                            :settings="distributorSettings"
                            :type="category"
                            :distributor-id="distributorId"
                          >
                          </settings-category>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <distributor-tab-settings
                  v-if="distributorSettings"
                  :user-id="distributorSettings"
                  @openSettingsAside="setSettingsAside"
                  @openEditSettingsAside="setEditSettingsAside"
                />
              </v-tab-item>

              <!-- <v-tab-item>
                <distributor-tab-blacklist :user-id="distributorBlacklistSettings"
                  v-if="distributorBlacklistSettings" />
              </v-tab-item> -->

              <v-tab-item>
                <product-usage
                  v-if="distributorUsage"
                  :user-id="distributorUsage"
                />
              </v-tab-item>

              <v-tab-item>
                <distributor-tab-reward
                  v-if="distributorReward"
                  :distributor-reward="distributorReward"
                  @openRewardAside="setRewardAside"
                  @openEditRewardAside="setEditRewardAside"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <distributor-avatar-aside
      v-if="aside"
      :id="distributorId"
      v-model="aside"
      @changed="aside = false;"
    />

    <distributor-settings-aside
      v-if="settingsAside"
      :id="distributorId"
      v-model="settingsAside"
      @changed="loadDistributorSettings(); settingsAside = false"
    />

    <distributor-edit-settings-aside
      v-if="editSettingsAside"
      :id="distributorId"
      v-model="editSettingsAside"
      :resources="resources"
      @changed="loadDistributorSettings(distributorId); editSettingsAside = false"
    />

    <distributor-reward-aside
      v-if="rewardAside"
      :id="distributorId"
      v-model="rewardAside"
      @changed="reloadReward(); rewardAside = false"
    />

    <distributor-edit-reward-aside
      v-if="editRewardAside"
      :id="distributorId"
      v-model="editRewardAside"
      :resources="rewardResources"
      @changed="reloadReward(); editRewardAside = false"
    />
  </div>
</template>

<script>
import { can } from '@/@core/libs/acl/utils'
import { useNotifyErrors } from '@/composables'
import distributorStore from '@/modules/distributor/store'
import router from '@/router'
import store from '@/store'
import { fetchDistributorSettingsKeyMap } from '@api/distributor/distributor'
import { mdiChartDonut, mdiCreditCardCheckOutline, mdiCurrencyUsd } from '@mdi/js'
import {
computed, defineAsyncComponent, onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import DistributorAvatarAside from './DistributorAvatarAside.vue'
import DistributorEditRewardAside from './DistributorEditRewardAside.vue'
import DistributorEditSettingsAside from './DistributorEditSettingsAside.vue'
import DistributorRewardAside from './DistributorRewardAside.vue'
import DistributorSettingsAside from './DistributorSettingsAside.vue'
import DistributorStatistics from './DistributorStatistics.vue'
import SettingsCategory from './SettingsCategory.vue'

// eslint-disable-next-line object-curly-newline
import useDistributorView from '../../composables/useDistributorView'

export default {
  components: {
    DistributorTeamList: defineAsyncComponent(() => import('@/modules/distributor/views/DistributorTeamList.vue')),
    DistributorProfilePanel: defineAsyncComponent(() => import('./distributor-profile-panel/DistributorProfilePanel.vue')),
    DistributorEditSettingsAside,
    DistributorSettingsAside,
    DistributorAvatarAside,
    DistributorEditRewardAside,
    DistributorRewardAside,
    DistributorStatistics,
    DistributorTabSettings: defineAsyncComponent(() => import('./distributor-tab-settings/DistributorTabSettings.vue')),
    DistributorTabReward: defineAsyncComponent(() => import('./distributor-tab-reward/DistributorTabReward.vue')),
    DistributorTabBlacklist: defineAsyncComponent(() => import('./distributor-tab-blacklist/DistributorTabBlacklist.vue')),
    ProductUsage: defineAsyncComponent(() => import('@/modules/product/components/ProductUsage.vue')),
    SettingsCategory,
  },
  setup() {
    const DISTRIBUTOR_MODULE_NAME = 'distributor'
    const distributorTab = ref(null)

    const {
      distributor,
      loadDistributor,
      distributorBalance,
      loadDistributorBalance,
      distributorSettings,
      loadDistributorSettings,
      distributorBlacklistSettings,
      loadDistributorBlacklistSettings,
      distributorUsage,
      loadDistributorUsage,
      distributorReward,
      loadDistributorReward,
    } = useDistributorView()

    const tabs = [
      {
        icon: mdiCreditCardCheckOutline,
        title: 'Teams',
        display: can('distributor_team:list', 'distributor_team_management'),
      },
      {
        icon: mdiCreditCardCheckOutline,
        title: 'Settings',
      },

      // {
      //   icon: mdiCurrencyUsd,
      //   title: 'Blacklist',
      // },
      {
        icon: mdiChartDonut,
        title: 'Usage',
      },
      {
        icon: mdiCurrencyUsd,
        title: 'Reward Campaigns',
      },
    ]

    // Register module
    if (!store.hasModule(DISTRIBUTOR_MODULE_NAME)) store.registerModule(DISTRIBUTOR_MODULE_NAME, distributorStore)

    const distributorId = parseInt(router.currentRoute.params.id)

    const reload = () => {
      loadDistributor(distributorId)
      loadDistributorBalance(distributorId)
      loadDistributorSettings(distributorId)
      loadDistributorBlacklistSettings(distributorId)
      loadDistributorBlacklistSettings(distributorId)
      loadDistributorUsage(distributorId)
      loadDistributorReward(distributorId)
    }

    onMounted(() => {
      reload()
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DISTRIBUTOR_MODULE_NAME)) store.unregisterModule(DISTRIBUTOR_MODULE_NAME)
    })

    const aside = ref(false)
    const setAside = () => {
      aside.value = true
    }

    const settingsAside = ref(false)
    const setSettingsAside = () => {
      settingsAside.value = true
    }

    const editSettingsAside = ref(false)
    const resources = ref(null)
    const setEditSettingsAside = item => {
      console.log('item', item)
      editSettingsAside.value = true
      resources.value = item
    }

    const rewardAside = ref(false)
    const setRewardAside = () => {
      rewardAside.value = true
    }

    const editRewardAside = ref(false)
    const rewardResources = ref(null)
    const setEditRewardAside = item => {
      console.log('item', item)
      editRewardAside.value = true
      rewardResources.value = item
    }

    const dialog = ref(false)
    const openDialog = () => {
      loadDistributorSettingsKeyMap()
      dialog.value = true
    }
    const dialogTab = ref(0)

    const distributorSettingsKeyMap = ref(null)
    const categoryOptions = ref(null)
    const keyOptions = ref(null)

    const loadDistributorSettingsKeyMap = id => fetchDistributorSettingsKeyMap(id)
      .then(({ data }) => {
        distributorSettingsKeyMap.value = data.data
        console.log(distributorSettingsKeyMap.value)

        categoryOptions.value = Object.keys(distributorSettingsKeyMap.value)
        keyOptions.value = categoryOptions.value.reduce((acc, category) => {
          acc.push({
            [category]: distributorSettingsKeyMap.value[category],
          })

          return acc
        }, [])
        console.log('categoryOptions:', categoryOptions.value)
        console.log('keyOptions:', keyOptions.value)
      })
      .catch(useNotifyErrors)

    const isMedicalCardSettingsEnabled = computed(() => {
      if (distributor.value) {
        return distributor.value.features.some(feature => feature.key === 'enable_medical')
      }

      return false
    })

    const isEmployeeBenefitEnabled = computed(() => {
      if (distributor.value) {
        return distributor.value.features.some(feature => feature.key === 'enable_employee_benefit')
      }

      return false
    })

    const reloadReward = () => {
      loadDistributorReward(distributorId)
    }

    return {
      reload,
      tabs,
      distributorTab,
      distributor,
      loadDistributor,
      setAside,
      aside,
      setSettingsAside,
      settingsAside,
      setEditSettingsAside,
      editSettingsAside,
      resources,
      distributorBalance,
      loadDistributorBalance,
      distributorSettings,
      loadDistributorSettings,
      distributorBlacklistSettings,
      loadDistributorBlacklistSettings,
      distributorId,
      distributorUsage,
      dialog,
      openDialog,
      dialogTab,
      distributorSettingsKeyMap,
      categoryOptions,
      keyOptions,
      rewardResources,
      isMedicalCardSettingsEnabled,
      editRewardAside,
      setEditRewardAside,
      distributorReward,
      isEmployeeBenefitEnabled,
      reloadReward,
      rewardAside,
      setRewardAside,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
../../composables/useDistributorView
