import { useNotifyErrors } from '@/composables'
import {
  fetchDistributor,
  fetchDistributorBlacklistSettings,
  fetchDistributorSettings,
  fetchDistributorUsage,
  fetchRewards,
  fetchtDistributorBalance,
} from '@api/distributor/distributor'
import { ref } from '@vue/composition-api'

const cached = []

export default function useList() {
  const distributor = ref(null)

  const loadDistributor = async id =>
    await fetchDistributor(id)
      .then(({ data }) => {
        distributor.value = data.data
      })
      .catch(useNotifyErrors)

  const distributorBalance = ref({ totals: null })

  const loadDistributorBalance = id =>
    fetchtDistributorBalance(id)
      .then(({ data }) => {
        distributorBalance.value = data.data
      })
      .catch(useNotifyErrors)

  const distributorSettings = ref(null)

  const loadDistributorSettings = id =>
    fetchDistributorSettings(id)
      .then(({ data }) => {
        distributorSettings.value = data.data
      })
      .catch(useNotifyErrors)

  const distributorBlacklistSettings = ref(null)

  const loadDistributorBlacklistSettings = id =>
    fetchDistributorBlacklistSettings(id)
      .then(({ data }) => {
        distributorBlacklistSettings.value = data.data
      })
      .catch(useNotifyErrors)

  const distributorUsage = ref(null)

  const loadDistributorUsage = id =>
    fetchDistributorUsage(id)
      .then(({ data }) => {
        distributorUsage.value = data.data
      })
      .catch(useNotifyErrors)

  const distributorReward = ref(null)

  const loadDistributorReward = async id =>
    await fetchRewards(id)
      .then(({ data }) => {
        distributorReward.value = data.data
      })
      .catch(useNotifyErrors)

  return {
    distributor,
    loadDistributor,
    distributorBalance,
    loadDistributorBalance,
    distributorSettings,
    loadDistributorSettings,
    distributorBlacklistSettings,
    loadDistributorBlacklistSettings,
    distributorUsage,
    loadDistributorUsage,
    distributorReward,
    loadDistributorReward,
  }
}
