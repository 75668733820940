<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field v-model="form.category" label="Category" item-text="title" item-value="value" disabled outlined />
        <v-text-field v-model="form.key" label="Key" item-text="title" item-value="value" disabled outlined />
        <v-text-field v-model="form.value" label="Value" outlined /> 
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" :loading="loading" @click="validate">
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert v-for="msg in errors" :key="msg" color="warning">
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const initialForm = {
      ...field('category', ''),
      ...field('key', ''),
      ...field('value', ''),
      ...field('value_type', ''),
    }
    const form = ref({
      ...initialForm,
    })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
    }
  },
}
</script>
