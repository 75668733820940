<template>
  <v-navigation-drawer :value="active" temporary touchless :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'" app @input="(val) => $emit('aside', val)">
    <div style="padding: 1rem;">
      <h1>Update distributor reward</h1>
    </div>
    <distributor-edit-reward-form :id="id" :loading="loading" :errors="errors" :resource="resources"
      @submit="handleSubmit" />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { updateReward } from '@api/distributor/distributor'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import DistributorEditRewardForm from './DistributorEditRewardForm.vue'

export default {
  components: {
    DistributorEditRewardForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [Number],
      default: false,
    },
    resources: {
      type: [Object],
      default: null,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const handleSubmit = async form => {
      loading.value = true
      console.log('props.id', props.id)
      console.log('props.resources.id', props.resources.id)
      console.log('data', form)
      const request = updateReward(props.id, props.resources.id, form)
      await request
        .then(async res => {
          emit('changed', props.id)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }
    return {
      handleSubmit,
      loading,
      errors,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
